import { computed, ref, watch } from "vue";
import { formatISO } from "date-fns";
import { reqAxios } from "@/composables/core/func";

import { useMainStore } from "@/store/main/index";
import type { IndxInterface } from "@/store/main/types/StateType";
import { useMyComposable } from "~/composables/core/conf";

type HandleMonthYearParams = {
  instance: number;
  month: number;
  year: number;
};

export const formatter = (dateString: Date = new Date()) => {
  const date = new Date(dateString);
  const formattedDate = date.toISOString().substr(0, 10);
  return formattedDate;
};

const checkOccurrence = (availDates: string[], times: string[]) => {
  const indx = useState<IndxInterface>("data");

  const occurrenceCount: any = {};

  const mainStore = useMainStore();
  const requiredOccurrences = computed(
    () => indx.value?.siteSettings?.book_per_hour || 1
  );
  // Count the occurrences of each item in the times array
  for (const time of times) {
    if (!occurrenceCount[time]) {
      occurrenceCount[time] = 1;
    } else {
      occurrenceCount[time]++;
    }
  }

  // Check if each item in availDates appears n times or more in the times array
  for (const date of availDates) {
    if (
      !occurrenceCount[date] ||
      occurrenceCount[date] < requiredOccurrences.value
    ) {
      return false;
    }
  }

  return true;
};

const filterByMaxOccurence = (arr: string[] | undefined): string[] => {
  const indx = useState<IndxInterface>("data");

  if (!arr?.length) return [];

  const mainStore = useMainStore();
  const requiredOccurrences = computed(
    () => indx.value?.siteSettings?.book_per_hour || 1
  );

  const occurrences = arr.reduce((acc: any, curr) => {
    acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
    return acc;
  }, {});

  const result = Object.keys(occurrences).filter(
    (key) => occurrences[key] >= requiredOccurrences.value
  );

  return result;
};

const fillFullDays = () => {
  const mainStore = useMainStore();

  const bookableHours = computed(() => {
    return mainStore.terms?.reserv_time?.map((el) => el?.title);
  });
  bookedDays.value.forEach((el) => {
    const currentDaysBookedHours = alreadyBooked.value?.[el];

    if (!bookableHours.value?.length) return;
    const isFullWithBooking = checkOccurrence(
      bookableHours.value,
      currentDaysBookedHours!
    );

    if (isFullWithBooking) {
      fullDays.value.push(el);
    }
  });
};

const bookedDays = ref<string[]>([]);
const fullDays = ref<string[]>([]);
const alreadyBooked = ref<{
  [key: string]: string[];
}>();

const formData = {
  formType: "bookedCalls",
  date: formatter(),
};

export const useCalendar = () => {
  const mainStore = useMainStore();
  const indx = useState<IndxInterface>("data");

  const _disabledDates = computed(
    () => indx.value?.siteSettings.rest_day || []
  );

  const bookableHours = computed(() => {
    return mainStore.terms?.reserv_time?.map((el) => el?.title);
  });
  const disabledDaysOfWeek = [0];

  const getBookedHours = (_selectedDate: string = formatter()) => {
    formData.date = _selectedDate;
    const result = bookedDays.value.find((el) => el === _selectedDate);
    const entries = Object.entries(alreadyBooked.value || []);
    const match = entries.find((el) => el[0] === result)?.[1];

    const filtered = filterByMaxOccurence(match);

    return filtered;
  };

  const disabledDates = (date: Date): boolean => {
    const dateString = formatISO(date, { representation: "date" });
    const dayOfWeek = date.getDay();

    return (
      fullDays.value.includes(dateString) ||
      _disabledDates.value.includes(dateString) ||
      disabledDaysOfWeek.includes(dayOfWeek)
    );
  };

  const getAvailableDays = async (
    monthIndex: number = new Date().getMonth()
  ) => {
    monthIndex = monthIndex <= 11 ? monthIndex + 1 : monthIndex;

    const conf = useMyComposable();
    
    const { data: response } = await useFetch<any>(conf.apiUrl + "gumbati/getBookedCalls", {
      method: "POST",
      body: {
        month: monthIndex,
      }
    });

    alreadyBooked.value = response?.value || [];
    bookedDays.value = Object.keys(alreadyBooked.value || {});

    fillFullDays();
  };

  const handleMonthYear = async (params: HandleMonthYearParams) => {
    await getAvailableDays(params.month);
  };

  watch(bookableHours, () => {
    fillFullDays();
  });

  const openNextStep = () => {};
  // const bookCall = async (time: string) => {
  //     await reqAxios("main/saveSubmitedForm", {
  //         formType: "bookedCalls",
  //         date: selectedDate,
  //         time,
  //     });
  // };

  return {
    getBookedHours,
    disabledDates,
    handleMonthYear,
    getAvailableDays,
    openNextStep,
    bookedDays,
  };
};
