/**
 * @author Beka Chkhaidze
 */

import { useMainStore } from "@/store/main/index";

export const alreadyPassed = (timeString: string) => {
    const mainStore = useMainStore();

    const currentTime = mainStore.serverTimeStamp
        ? new Date(mainStore.serverTimeStamp)
        : new Date();
    const targetDate = new Date();

    // Extract hour and minute from the time string
    const [hour, minute] = timeString.split(":");

    // Set the target time
    targetDate.setHours(parseInt(hour), parseInt(minute), 0, 0);

    return targetDate < currentTime;
};
