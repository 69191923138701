<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */
import { ref, onBeforeMount, computed } from "vue";
import { format } from "date-fns";

import { useBookingStore } from "@/store/booking";
import { alreadyPassed } from "@/composables/utils/date";
import type { BaseTaxonomy } from "@/store/main/types/widgets-terms/terms";

import { getTaxonomySS } from "@/composables/core/func";

import { useMainStore } from "@/store/main/index";
import SmallLeft from "@/components/regularComponents/UIComponents/SVG/chevrons/SmallLeft.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";

defineProps<{
	calendarModal: number | Date;
	bookedHours?: string[];
	useBorders?: boolean;
	hideTitle?: boolean;
	showBack?: boolean;
}>();

const emit = defineEmits<{
	(e: "select", item: BaseTaxonomy): void;
}>();

await getTaxonomySS("reserv_time");

const mainStore = useMainStore();
const bookingStore = useBookingStore();
const selectedHourId = ref();

const availableTimes = computed(() => {
	const todayDayNum = new Date(mainStore.serverTimeStamp!).getDate();
	const selectedDayNum = new Date(bookingStore.date).getDate();

	const selectedDate = new Date(bookingStore.date)?.getDay();
	const isSaturday = selectedDate === 6;
	const reservedTimes = mainStore.terms?.reserv_time;

	let result = isSaturday
		? reservedTimes?.filter((el) => el.saturday)
		: reservedTimes;

	if (todayDayNum === selectedDayNum) {
		result = result?.filter((el) => {
			const isPassed = alreadyPassed(el?.title);
			return !isPassed;
		});
	}

	return result;
});
const { t } = useI18n();
const formatDate = (calendarModal: Date | number) => {
	const foramted = format(calendarModal, "EEEE, MMMM d");
	const [dayName, rest] = foramted.split(",");
	const [_, monthName, dayNum] = rest.split(" ");

	return `<span>${t(dayName?.toLowerCase())}</span><br /><span>${t(
		`calendar-${monthName?.toLowerCase()}`
	)} ${dayNum}</span>`;
};

const onSelect = (item: BaseTaxonomy) => {
	selectedHourId.value = item.id;
	emit("select", item);
};
</script>

<template>
	<div
		:class="['time-list', { 'time-list--borders': useBorders }]"
	>
		<!-- @mouseenter="toggleScroll(true)"
		@mouseleave="toggleScroll(false)" -->
		<template v-if="availableTimes?.length">
			<Button
				v-if="showBack"
				class="video-call__back"
				:isBack="true"
				:btnType="8"
				@click="$emit('back')"
			>
				<SmallLeft />
				<h2 class="video-call__back_text">
					{{ $t("back") }}
				</h2>
			</Button>
			<p
				v-if="!hideTitle"
				class="value text-center"
				v-html="formatDate(calendarModal)"
			/>
			<template v-for="(item, index) in availableTimes" :key="index">
				<div
					:class="[
						'time-item',
						{
							'time-item--disabled ev-none op-6': bookedHours?.includes(
								item?.title
							),
						},
						{ 'time-item--selected': selectedHourId == item.id },
					]"
					@click="onSelect(item)"
				>
					<p class="time-item__text text-center">{{ item?.title }}</p>
				</div>
			</template>
		</template>
		<p v-else class="text-center">{{ $t("no available hours") }}</p>
	</div>
</template>

<style lang="scss">
	.time-list {
		// --max-h: 1001px;

		// @include maxq(desktop-2k) {
		//   --max-h: 853px;
		// }

		// @include maxq(desktop-lg) {
		//   --max-h: 561px;
		// }

		max-height: var(--max-h);
		overflow-y: auto;
		@include scrollbar;

		@include maxq(mobile) {
			max-height: unset;
		}

		width: 100%;
		background-color: $lotion;
		padding: 46px 33px;
		border-left: 1px solid $silver;

		@include maxq(mobile) {
			padding: 0;
			border: unset;
		}

		&--borders {
			border: 1px solid $silver;
			border-left: 1px solid $silver;

			height: 100%;

			// @include maxq(desktop-lg) {
			//   max-height: var(--max-h);
			// }
		}

		.value {
			font-size: 30px;

			@include maxq(desktop-2k) {
				font-size: 24px;
			}

			@include maxq(desktop-lg) {
				font-size: unset;
			}
			color: $black;
			margin-bottom: 30px;
		}

		.time-item {
			cursor: pointer;
			border: 1px solid $silver;
			margin-bottom: 16px;

			@include maxq(desktop-lg) {
				margin-bottom: 8px;
			}

			@include easeInOut(350ms, background-color);

			@include hover {
				background-color: #f3f3f3;
			}

			&--selected,
			&.active,
			&:active {
				background-color: $mango;
			}

			&__text {
				font-size: 28px;
				padding-bottom: 20px;
				padding-top: 20px;
				color: #000000;
				opacity: 0.6;
				@include weight(700);

				@include maxq(desktop-2k) {
					font-size: 22px;
					padding-bottom: 18px;
					padding-top: 18px;
				}

				@include maxq(desktop-lg) {
					font-size: 15px;
					line-height: 20px;
					padding-top: 16px;
					padding-bottom: 16px;
				}
			}
		}
	}
</style>
